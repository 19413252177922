import { ReactElement } from 'react';
import withGlobalAppLayout from '../../modules/ui/containers/HOC/withGlobalAppLayout';
import PrivacyPolicy from '../../modules/ui/pages/PrivacyPolicy';

export default function index(props: any): ReactElement
{
    return withGlobalAppLayout(PrivacyPolicy, {
        locale: props.pageContext.locale,
        hideGfLogo: false,
        ...props,
    });
}
