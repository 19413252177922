export const content = {
    de: `
Wir schätzen Ihre Privatsphäre und verpflichten uns, dafür zu sorgen, dass Sie unsere Website vertraulich besuchen können. Im Folgenden erläutern wir, welche Arten von      Daten wir aus Ihrer Nutzung unserer Website erfassen, sowie die verschiedenen Dienste, die wir zur Verarbeitung dieser Daten nutzen.
## Welche Benutzerdaten werden erfasst?
        
### Cookies
Google Analytics verwendet so genannte Cookies, kleine Textdateien, die in Ihrem Browser gespeichert werden. Cookies helfen Google Analytics, festzustellen, ob Sie ein wiederkehrender Benutzer oder ein neuer Benutzer auf unserer Website sind. Diese 'Cookies' enthalten keine personenbezogenen Daten und bestehen nur aus einer ID.
    
### IP-Adresse
Google Analytics zeichnet eine anonymisierte Version der IP-Adressen der Nutzer auf, die unsere Website besuchen. Um die Privatsphäre der Benutzer zu gewährleisten, wird der letzte Teil Ihrer IP-Adresse auf '0' gesetzt. Ihre aktuelle IP-Adresse wird niemals auf einer Festplatte auf unseren oder den Servern von Google gespeichert. Das heißt, wir können nur mit Sicherheit das Land kennen, aus dem die IP-Adresse stammt.
    
### Anonyme Geräte- und Browserinformationen
Google Analytics erfasst Informationen über den Gerätetyp und den Browser, mit dem Sie auf unsere Website zugreifen. Dies beinhaltet auch keine persönlich identifizierbaren Informationen.
  
### Newsletter-Abonnement 
Glaubensfutter bietet ein Newsletter-Abonnement an, bei dem die Abonnenten regelmäßig neue Publikationen und andere wichtige Glaubensfutter-Updates erhalten. Wir speichern nur die E-Mail-Adresse des Abonnenten in unserem System, damit wir ihn auch in Zukunft per E-Mail kontaktieren können.  Obwohl die Daten des Nutzers in unserem System gespeichert sind, verwenden wir Mailgun, um die E-Mail tatsächlich zu versenden. 

Die Daten der Abonnenten werden auf den Servern von Heroku in der EU gespeichert, niemals an Dritte weitergegeben und nicht für den Versand von Werbung verwendet. Die Abmeldung ist über einen Link in jeder Newsletter-E-Mail möglich, die wir versenden. Bei der Abmeldung wird die E-Mail-Adresse des Nutzers vollständig aus unserem System entfernt. 

Mit der Zustimmung zum Erhalt unseres Newsletters erklärt sich der Nutzer mit der Speicherung seiner E-Mail-Adresse einverstanden, um neue Publikationen von Glaubensfutter per E-Mail zu erhalten, sowie andere unregelmäßige, aber wichtige Informationen über die Aktivitäten und Möglichkeiten von Glaubensfutter, mehr über die christliche Philosophie zu erfahren.  


### Informationen zur Fehlermeldung
Wir verwenden Sentry zur Fehlerverfolgung, so dass wir informiert sind, wann, wo und wie oft unsere Benutzer auf Fehler auf unserer Website stoßen. Bei Fehlern ist es unwahrscheinlich, dass sie personenbezogene Daten enthalten, aber wenn dies der Fall ist, verwendet Sentry einen serverseitigen Datenwäscher, der alle Informationen entfernt, die persönlich identifizierbar sein könnten.



## Glaubensfutter genutzten Dienste
### Google Analytics
Diese Website benutzt Google Analytics, einen Webanalysedienst der Google, Inc. ('Google'). Google Analytics verwendet so genannte 'Cookies', Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden an einen Server von Google in den USA übertragen und dort gespeichert.

Die IP-Adresse, die Ihr Browser im Rahmen von Google Analytics übermittelt, wird nicht mit anderen Daten von Google in Verbindung gebracht. Google wird diese Informationen im Auftrag des Betreibers dieser Website verwenden, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen

Google wird diese Informationen im Auftrag des Betreibers dieser Website verwenden, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.

Google erklärt, dass die Erhebung, Verarbeitung und Speicherung von Daten mit Google Analytics (Google Analytics-Daten) sicher und vertraulich ist. Diese Daten werden verwendet, um den Service von Google Analytics aufrechtzuerhalten und zu schützen, um systemkritische Operationen durchzuführen, und in seltenen Fällen aus rechtlichen Gründen, wie in unserer Datenschutzerklärung beschrieben. Alle Optionen für die gemeinsame Datennutzung in unseren Konfigurationen der Analyseseite sind deaktiviert. Wir kombinieren den Google-Analytics-Service nicht mit anderen Google-Marketing-Services wie Adwords, Tag Manager, Werbung, Retargeting oder anderen. Wenn Sie mehr über Google Analytics erfahren möchten, können Sie [ihre Website](http://www.google.com/intl/en/analytics/privacyoverview.html) besuchen. 

### Sentry
Sentry ist eine Fehlerverfolgungssoftware, die GDRP-konform ist. Sie erfüllen internationale und GDRP-Datenübertragungs- und Speicherstandards. Sentry hört in der Regel auf Fehler, die in Ihrer Anwendung auftreten, und wenn sie auftreten, werden die Fehlerinformationen zur Analyse an die Server von Sentry gesendet.

Sentry kann auch als benutzerdefinierter Logger verwendet werden, um Entwickler über verschiedene Ereignisse in ihren Anwendungen zu informieren, die wichtig sind, aber nicht unbedingt Fehler verursachen. In diesen Fällen senden wir keine personenbezogenen Daten als Protokolle. Sentry verwendet standardmäßig einen 'Datenwäscher', der potenziell personenbezogene Daten aus allen Berichten entfernt, die an die Server gesendet werden, um die Vertraulichkeit des Benutzers zu schützen. Für weitere Informationen können Sie [deren Website](https://docs.sentry.io/data-management/sensitive-data/#server-side-scrubbing) besuchen.



## Weitere Optionen
### Haben Sie weitere Fragen?
Für weitere Fragen darüber, wie wir Informationen aus Ihrer Nutzung unserer Dienste nutzen, können Sie sich gerne an uns über unsere Support Email wenden.

[info@glaubensfutter.de](mailto:info@glaubensfutter.de)
### Deaktivieren Sie das Google Analytics-Tracking
Wenn Sie sicherstellen möchten, dass wir die Nutzung dieser Website durch Ihr Gerät nicht nachverfolgen, können Sie unten klicken, um Google Analytics zu deaktivieren. Dadurch wird Google Analytics angewiesen, keine weiteren Informationen über Ihre Nutzung unserer Website zu senden. Beachten Sie, dass Sie dies in Zukunft bei jedem Besuch unserer Website wiederholen müssen.

    `,
    en: `
We value your privacy and are committed to ensuring that you can visit our site confidentially. Below we outline the types of data we collect from your usage of our site, as well as the different services that we use to process this data.

## What User Data Is Collected?

### Cookies
Google Analytics uses 'cookies', which are small text files stored in your browser. Cookies help Google Analytics to determine if you are a returning user or a new user on our site. These 'cookies' contain no personally identifiable information and consist only of an ID. The 'cookie' is set when you first visit the site, and any further information that is sent to Google Analytics about your site usage is associated with the ID in this cookie.

### IP Address
Google Analytics records an anonymized version of the IP addresses of users visiting our site. In order to ensure user privacy, the last part of your IP address is set to '0'. Your actual IP address is never saved to disk on our or Google's servers. This means we can only know with confidence the country that the IP address originated from.

### Anonymous device and browser Information
Google Analytics records information about the kind of devices you use to access our site. None of this information will be connected with personally identifiable data at any time.

### Newsletter subscription
Glaubensfutter offers a newsletter subscription in which subscribers are regularly sent new publications and other important Glaubensfutter updates. We store only the subscriber's email address in our system, in order that we can continue to contact them via email in the future.  Although the user's data is stored in our system, we use Mailgun to actually distribute the email. 

Subscriber's data is stored on Heroku servers in the EU, never forwarded to third parties, and is not used to send advertisements. Unsubscribing is possible via a link in each newsletter email that we send. On unsubscribing, the user's email address is fully removed from our system.

By agreeing to receive our newsletter, the user agrees to the storage of their email address in order to receive new publications from Glaubensfutter via email, as well as other infrequent but important updates about the operations and opportunities Glaubensfutter provides to learn more about Christian philosophy.  

### Error Message Information
We use Sentry for error tracking, so that we can be informed when, where, and how often our users encounter errors on our site. Errors are unlikely to contain personally identifiable information, but if they do, Sentry makes use of a server-side data-scrubber that removes all information that could be personally identifiable.



## Services Used By Glaubensfutter

### Google Analytics
This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website will be transmitted to and stored by Google on servers in the United States.

IP-anonymisation is active on this website. In this case, your IP address will be truncated within the area of Member States of the European Union or other parties to the Agreement on the European Economic Area. Only in exceptional cases the whole IP address will be first transfered to a Google server in the USA and truncated there.

The IP-address, that your Browser conveys within the scope of Google Analytics, will not be associated with any other data held by Google. Google will use this information on behalf of the operator of this website for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing them other services relating to website activity and internet usage.

Google states that Data {we} collect, process, and store using Google Analytics (Google Analytics data) is secure and kept confidential. This data is used to maintain and protect the Google Analytics service, to perform system critical operations, and in rare exceptions for legal reasons as described in our privacy policy.' All data sharing options in our analytics site configurations are turned off. We do not combine Google analytics service with other Google marketing services, like Adwords, Tag Manager, Advertising, Retargeting, or others. If you would like to know more about Google analytics, you may visit [their website](http://www.google.com/intl/en/analytics/privacyoverview.html).

### Sentry
Sentry is an error tracking software that is GDRP compliant. It complies with international and GDRP data transfer and storage standards. Sentry typically listens to errors that occur in your application, and when they occur, the error information is sent to Sentry's servers for analysis. Sentry can also be used as a custom logger to inform developers about various events in their applications that are important but do not necessarily cause errors. In these cases, we do not send personally identifiable information as logs. By default, Sentry uses a 'data washer' that removes potentially personally identifiable information from all reports sent to the servers to protect the confidentiality of the user. For more information, please visit [their website](https://docs.sentry.io/data-management/sensitive-data/#server-side-scrubbing).



## Further Options
### Do you have further questions?
You are welcome to contact us at our support email for any further questions about how we make use of information from your usage of our services.

[info@glaubensfutter.de](mailto:info@glaubensfutter.de)

### Opt out of Google Analytics tracking
If you would like to ensure that we do not track your device's usage of this site, you may click below to disable Google analytics. This will instruct Google analytics not to send any further information about your usage of our site. Bear in mind that in the future you will need to repeat this every time you visit our site.

      `,
};
