import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import PageHeader from '../../atoms/PageHeader';
import Head from '../../atoms/Head';
import { content } from './PrivacyPolicy.markdown';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import Module from '../../../application/Service/Module';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { GFBrand } from '../../models/GFBrand';

class PrivacyPolicy extends React.Component
{
    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    render()
    {
        const { locale } = this.props;

        return (
            <section className="privacy-policy-container layout-lower-spacing">
                <Head
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Datenschutz Erklärung' : 'Privacy Policy'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forPrivacyPolicyPage(this.props.pageContext.locale).getFullUrl()}
                />
                <PageHeader
                    title="privacy_policy"
                    translateTitle={true}
                    showHr={true}
                />
                <div className="article-reader">
                    <ReactMarkdown>
                        {locale === 'de' ? content.de : content.en}
                    </ReactMarkdown>
                    <span style={{ display: 'inline' }} className="ga-optout" onClick={Module.get().gaOptOut}> <p style={{ display: 'inline' }}>{this.props.locale === 'en' ? 'Google Analytics - Click here to Opt out' : 'Google Analytics - Klicken Sie hier, um sich abzumelden.'}</p></span>
                </div>
            </section>
        );
    }
}

PrivacyPolicy.propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    pageContext: PropTypes.object,
};

export default PrivacyPolicy;
